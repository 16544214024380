.main_div_navbar {
  width: 83%;
  margin-left: 10%;
  /* background-color: #469efc; */
}

.my_top_blue_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717 !important;

  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}

.button_navbar_end {
  border-radius: 110px;
  /* border: 1px solid #fff !important; */
  background: transparent !important;
  width: 316px;
  height: 65px;
  background-color: #abff30 !important;

  cursor: pointer !important;
}

.call_us_for_free {
  color: #fff;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  text-align: center;
}

.phone_number_navbar {
  color: #fff;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px !important;
  letter-spacing: 0.48px;
  text-align: center;
}

.phone_logo_navbar {
  width: 30px;
  height: 30px;
  margin-top: 45%;
}

.company_logo_image {
  width: 10vw;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.navbar_text_links {
  margin-left: 7% !important;
}

.why_review_faq_text:hover {
  color: #fbef00 !important;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  padding-right: 20px !important;
  text-decoration: none;
  /* Remove the default underline */
  position: relative;
  /* Needed for absolute positioning of the pseudo-element */
}

/* 
  .why_review_faq_text:hover::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -3%;
    left: 5%;
    width: 70%;
    height: 4px;
    background-color: #062b63;
  } */

@media only screen and (max-width: 2760px) {
  .main_div_navbar {
    width: 83% !important;
    margin-left: 10% !important;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    width: 15vw;
    height: 3vw;
    cursor: pointer;
    background-color: #abff30 !important;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 0.8v9;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 30px;
    height: 30px;
    margin-top: 45%;
    margin-left: 0.8vw;
  }

  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

  .home_offer_text {
    color: #21201f;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .direct_text {
    color: #469efc;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    margin-top: -20px;
  }

  .navbar_text_links {
    margin-left: 20% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .why_review_faq_text:hover {
    text-decoration-thickness: 4px;
    stroke-width: 4px;
    color: #fbef00 !important;

    font-family: "Poppins";
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .topic_name {
    margin-left: -40px !important;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1900px) {
  .main_div_navbar {
    width: 83% !important;
    margin-left: 10% !important;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    width: 316px;
    height: 65px;
    color: #171717 !important;
    cursor: pointer;
    background-color: #abff30 !important;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 30px;
    height: 30px;
    margin-top: 45%;
  }

  .home_offer_text {
    color: #21201f;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .direct_text {
    color: #469efc;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    margin-top: -20px;
  }

  .navbar_text_links {
    margin-left: 10% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .why_review_faq_text:hover {
    text-decoration-thickness: 4px;
    color: #fbef00 !important;

    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    stroke-width: 4px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .my_top_blue_btn {
    font-size: 24px !important;
    color: #171717 !important;
  }

  /* .why_review_faq_text:hover::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -3%;
      left: 8%;
      width: 70%;
      height: 4px;
      background-color: #062b63;
    } */

  .main_div_navbar {
    width: 87% !important;
    margin-left: 8% !important;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    color: #171717 !important;

    width: 240px;
    height: 60px;
    cursor: pointer;
    background-color: #abff30 !important;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 25px;
    height: 25px;
    margin-top: 95%;
    margin-left: 20% !important;
  }

  .navbar_text_links {
    margin-left: 10% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .why_review_faq_text:hover {
    font-family: "Poppins";
    text-decoration-thickness: 4px;
    stroke-width: 4px;
    color: #fbef00 !important;

    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .topic_name {
    margin-left: -80px !important;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1440px) {
  .my_top_blue_btn {
    font-size: 24px !important;
    color: #171717 !important;
  }

  .main_div_navbar {
    width: 83%;
    margin-left: 10%;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    color: #171717 !important;

    width: 240px;
    height: 60px;
    cursor: pointer;
    background-color: #abff30 !important;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 25px;
    height: 25px;
    margin-top: 95%;
    margin-left: 20% !important;
  }

  .navbar_text_links {
    margin-left: 0% !important;
  }

  .navbar-brand {
    margin-right: 12% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
    /* margin-left: 20%; */
  }

  .why_review_faq_text:hover {
    font-family: "Poppins";
    stroke-width: 4px;
    text-decoration-thickness: 4px;
    color: #fbef00 !important;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .topic_name {
    margin-left: -80px !important;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1300px) {
  .phone_div {
    margin-top: 7px !important;
  }

  .my_top_blue_btn {
    color: #171717 !important;

    font-size: 20px !important;
  }

  .main_div_navbar {
    width: 94% !important;
    margin-left: 2% !important;
  }

  .topic_name {
    margin-left: -80px !important;
    margin-right: 20px;
  }

  .home_offer_text {
    color: #21201f;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .direct_text {
    color: #469efc;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    margin-top: -20px;
  }

  .navbar_text_links {
    margin-left: 10% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 20px !important;
  }

  .why_review_faq_text:hover {
    stroke-width: 4px;
    color: #fbef00 !important;

    font-family: "Poppins";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 20px !important;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    width: 210px;
    color: #171717 !important;

    height: 55px;
    background-color: #abff30 !important;

    cursor: pointer;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 20px;
    height: 20px;
    margin-top: 100%;
    margin-left: 45% !important;
  }
}

@media only screen and (max-width: 1080px) {
  .main_div_navbar {
    width: 94% !important;
    margin-left: 2% !important;
  }

  .topic_name {
    margin-left: -80px !important;
    margin-right: 20px;
  }

  .home_offer_text {
    color: #21201f;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .direct_text {
    color: #469efc;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    margin-top: -20px;
  }

  .navbar_text_links {
    margin-left: 0% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 20px !important;
  }

  .why_review_faq_text:hover {
    font-family: "Poppins";
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    color: #fbef00 !important;

    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 20px !important;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    width: 210px;
    height: 55px;
    background-color: #abff30 !important;

    cursor: pointer;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 20px;
    height: 20px;
    margin-top: 100%;
    margin-left: 45% !important;
  }
}

@media only screen and (max-width: 768px) {
    .company_logo_image {
        width: 90px !important;
      }
  .main_div_navbar {
    width: 94% !important;
    margin-left: 2% !important;
  }

  .topic_name {
    margin-left: -30px !important;
    margin-right: 20px;
  }

  .home_offer_text {
    color: #21201f;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .direct_text {
    color: #469efc;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    margin-top: -20px;
  }

  .navbar_text_links {
    margin-left: 0% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 20px !important;
  }

  .why_review_faq_text:hover {
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;  color: #fbef00 !important;

    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .button_navbar_end {
    border-radius: 110px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    width: 210px;
    height: 55px;
    cursor: pointer;
    background-color: #abff30 !important;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 20px;
    height: 20px;
    margin-top: 100%;
    margin-left: 45% !important;
  }
}

@media only screen and (max-width: 446px) {
  .my_top_blue_btn {
    color: #171717 !important;

    font-size: 15px !important;
  }

  .phone_div {
    margin-top: 4px !important;
  }

  .main_div_navbar {
    width: 94% !important;
    margin-left: 2% !important;
  }

  .topic_name {
    margin-left: -18% !important;
    /* margin-right: 20px; */
  }

  .home_offer_text {
    color: #21201f;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .direct_text {
    color: #469efc;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.7px;
    text-align: center;
    margin-top: -20px;
  }

  .navbar_text_links {
    margin-left: 0% !important;
  }

  .why_review_faq_text {
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 20px !important;
  }

  .why_review_faq_text:hover {
    color: #fbef00 !important;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    padding-right: 40px !important;
  }

  .button_navbar_end {
    border-radius: 70px;
    /* border: 1px solid #fff !important; */
    background: transparent !important;
    width: 180px;
    height: 45px;
    cursor: pointer;
    background-color: #abff30 !important;
  }

  .call_us_for_free {
    color: #fff;
    font-family: "Poppins";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .phone_number_navbar {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    letter-spacing: 0.48px;
    text-align: center;
  }

  .phone_logo_navbar {
    width: 20px;
    height: 20px;
    margin-top: 100%;
    margin-left: 45% !important;
  }
}

@media only screen and (max-width: 991px) {
  .button_navbar_end {
    margin-bottom: 2vw !important;
    margin-left: 1vw;
  }

  /* .why_review_faq_text:hover::after {
      content: none;
      display: block;
      position: absolute;
      bottom: -3%;
      left: 5%;
      width: 70%;
      height: 4px;
      background-color: #062b63;
    } */
}


.nav-link:focus, .nav-link:hover {
    color: #fff !important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: white;
}

.navbar-toggler {

  background-color: white;
}