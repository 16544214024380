@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");


.main_buyerform_div{
    width: 100%;
    height: auto;
    padding-bottom: 5vw;
}

.buyer_form_content{
    margin-top: 3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.america_form_left_flex{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1vw;
}

.americaa_text{
    color: #FFF;
font-family: "Lato";
font-size: 5.2vw;
font-style: normal;
font-weight: 800;
line-height: 100%; /* 100px */
letter-spacing: 5px;
}

.below_america{
    color: #FBFBFB;
font-family: Lato;
font-size: 1.8vw;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.blurry_card {
    margin-top:1vw !important;
    border-radius: 1.6vw;
    border: 0.2vw solid #ABFF30;
    background: #171717 !important;
    /* box-shadow: 0 0 0.6vw #ECD719; */

    width: 30vw;
    height: 17vw;
    padding: 1.5vw;
  }
  
  .card_content {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column !important;
    gap: 1vw;
  }
  
  .blurry_card_heading {
    color: #fff;
    font-family: "lato";
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
  }
  
  ::placeholder {
    color: #AAA;
    font-family: "Lato";
    font-size: 0.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    /* padding: 0.2vw; */
  }
  
  .actual_form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.2vw;
  }
  
  .my_form_inputs {
    border: none !important;
    width: 11vw;
    height: 2vw;
    margin: 0.5vw;
    padding-left: 0.7vw;
    outline: none;
    background-color:#252525 ;
    color: #fff;
  }
  
  .address_inputs {
    background-color:#252525 ;

    height: 2vw;
    width: 23vw;
    padding-left: 0.7vw;
    outline: none;
    border: none;
  }
  
  .form_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .my_form_button {
    font-size: 0.8vw;
    color: #000;
    width: 12vw;
    height: 2.5vw;
  font-weight: 600 !important;
  border-radius: 35px;
  border: 1px solid #72C000;
  background: #ABFF30;
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
  box-shadow: 0px 2px #72C000 ;
  }
  
  .btn_class_center {
    margin-top: 0.8vw;
  }
  .bullet_img {
    width: 26vw;
  }
  
  .my_bullets {
    margin-left: 9vw;
  }
  
  @media only screen and (max-width: 1100px) {
    .buyer_form_content {
      gap: 1vw;
    }

    .buyer_form_content {
    
        margin-top: 0vw;
      }
    .blurry_card {
   
      width: 300px;
      height: 190px;
      padding: 17px;
    }
  
    .card_content {
      display: flex;
      align-self: center;
      justify-content: center;
      flex-direction: column !important;
      gap: 15px;
    }
  
    .blurry_card_heading {
     
   
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: center;
    }
  
    ::placeholder {
      color: #585858;
      font-family: "Lato";
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.7px;
      /* padding: 0.2vw; */
    }
  
    .actual_form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2px;
    }
  
    .my_form_inputs {
      border: none !important;
      width: 130px;
      height: 25px;
      margin: 0.5vw;
      padding-left: 0.7vw;
      outline: none;
      padding-bottom: 7px;
    }
  
    .address_inputs {
      width: 270px;
      height: 25px;
      padding-bottom: 7px;
      padding-left: 0.7vw;
    }
  
    .form_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .my_form_button {
      font-size: 8px;
      color: #000;
      width: 110px;
      height: 25px;
    }
  
    .btn_class_center {
      margin-top: 4px;
    }
  }
  
  @media only screen and (max-width: 767px) {

  
   
  
    .below_america_text {
      margin-bottom: 2vw;
      font-size: 2.5vw;
    }
  
    .form_bullets_div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6vw;
    }
  
    .my_bullets {
      margin-top: 0vw !important;
      margin-left: 3vw;
    }
  
    .bullet_img {
      width: 37vw;
    }
  }
  
  @media only screen and (max-width: 600px) {
      
.buyer_form_content{
  margin-top: 3vw;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  gap: 4vw;

}
.america_form_left_flex{
  
  gap: 3vw;
}

.house_img_div{
  margin-right: 7vw !important;
}

.house_img{
  width: 60vw !important;
}
.america_text{
  font-size: 6vw !important;
}
.below_america{
  font-size: 2.6vw !important;
}
  }
  
  @media only screen and (max-width: 500px) {

    
    .below_america_text {
      font-size: 2.5vw;
    }
  
    .blurry_card {
      border-radius: 3.5vw;
     
      width: 63vw;
      height: 40vw !important;
      padding: 3vw;
    }
  
    .card_content {
      display: flex;
      align-self: center;
      justify-content: center;
      flex-direction: column !important;
      gap: 3vw;
    }
  
    .blurry_card_heading {
      font-size: 2vw;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.9px;
      text-align: center;
    }
  
    ::placeholder {
      color: #585858;
      font-family: "Lato";
      font-size: 2vw;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.7px;
      /* padding: 0.2vw; */
    }
  
    .actual_form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1vw;
    }
  
    .my_form_inputs {
      border: none !important;
      width: 26vw;
      height: 5.5vw;
      margin: 0.5vw;
      padding-left: 0.7vw;
      outline: none;
      padding-bottom: 7px;
    }
  
    .address_inputs {
      width: 53.4vw;
      height: 5.7vw;
      padding-bottom: 7px;
      padding-left: 0.7vw;
    }
  
    .form_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .my_form_button {
      font-size: 8px;
      width: 110px;
      height: 25px;
    }
  
    .btn_class_center {
      margin-top: 4px;
    }
  }
  
  @media only screen and (max-width: 420px) {
    .blurry_card {
      width: 63vw;
      height: 45vw !important;
      padding: 3vw;
    }
  
    .bullet_img {
      width: 50vw;
    }
  
    .buyer_form_content {
      margin: 0vw 2vw;
    }
  
    .america_text {
      font-size: 4vw;
    }
    .below_america_text {
      font-size: 2.5vw;
    }
  }
  

  .house_img{
    width: 40vw;
  }